<template>
  <div class="stepsdemo-content">
    <br />
    <!-- <i class="pi pi-fw pi-lock" /> -->
    <br />
    <div class="p-grid">
      <div class="p-col-12">
        <div class>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-3">
              <label for="name">Nom <span class="p-text-bold text-red">*</span></label>
              <input class="p-inputtext p-component" :disabled="true" :value="loggedUser.lastName" type="text" />
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label for="surname">Prénom <span class="p-text-bold text-red">*</span></label>
              <input class="p-inputtext p-component" :disabled="true" :value="loggedUser.firstName" type="text" />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="companyName">Nom de l'entreprise <span class="p-text-bold text-red">*</span></label>
              <input
                :disabled="true"
                :value="loggedUser?.entreprise?.name"
                class="p-inputtext p-component"
                type="text"
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="adresse">Adresse de l'entreprise <span class="p-text-bold text-red">*</span></label>
              <input
                class="p-inputtext p-component"
                :disabled="true"
                :value="loggedUser?.entreprise?.address"
                type="text"
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="state">Pays <span class="p-text-bold text-red">*</span></label>
              <input
                class="p-inputtext p-component"
                :disabled="true"
                :value="loggedUser?.entreprise?.pays?.name"
                type="text"
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="mobile">Téléphone 1 <span class="p-text-bold text-red">*</span></label>
              <input
                class="p-inputtext p-component no-arrow"
                :disabled="true"
                :value="loggedUser?.mobile"
                type="number"
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="fixe">Téléphone 2</label>
              <input
                class="p-inputtext p-component no-arrow"
                :disabled="true"
                :value="loggedUser?.phone"
                type="number"
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="fonction">Fonction dans l'entreprise <span class="p-text-bold text-red">*</span></label>
              <input
                class="p-inputtext p-component"
                :disabled="true"
                :value="loggedUser?.positionDescription"
                type="text"
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="email">E-mail <span class="p-text-bold text-red">*</span></label>
              <input
                class="p-inputtext p-component"
                :disabled="true"
                :value="loggedUser?.entreprise?.email"
                type="text"
              />
            </div>
          </div>

          <br />
          <div>
            <h5>Sélectionner un mode de paiement</h5>
            <div class>
              <h5 :style="{ color: '#ff0000', fontWeight: '600' }">
                Si vous êtes exonérés de TVA, merci de sélectionner le mode de paiement chèque ou virement, <br />et de
                joindre au justificatif de paiement le justificatif d'exonération fiscale.
              </h5>

              <span
                >NB : Pour les paiements par carte bancaire ou paiement mobile, veuillez passer par le navigateur
                <b>Google Chrome</b> ou <b>Microsoft Edge</b></span
              >
              <br /><br />
              <div class="p-col-12 p-md-4">
                <div v-bind:style="{ width: '80%' }" class="p-field-radiobutton">
                  <RadioButton id="type-paiement-card-id" v-model="payload.typePaiement" :value="paymentMethods.card" />
                  <label for="type-paiement-card-id">Carte Bancaire ou Paiement mobile</label>
                </div>
              </div>
              <div class="p-col-12 p-md-4">
                <div class="p-field-radiobutton">
                  <RadioButton
                    id="type-paiement-wire-transfer-id"
                    v-model="payload.typePaiement"
                    :value="paymentMethods.wireTransfer"
                  />
                  <label for="type-paiement-wire-transfer-id">Virement Bancaire</label>
                </div>
              </div>
              <div class="p-col-12 p-md-4">
                <div class="p-field-radiobutton">
                  <RadioButton
                    id="type-paiement-pay-check-id"
                    v-model="payload.typePaiement"
                    :value="paymentMethods.payCheck"
                  />
                  <label for="type-paiement-pay-check-id">Chèque</label>
                </div>
              </div>
            </div>
            <div v-show="canShowPaymentMethod(paymentMethods.card)" v-bind:style="{ width: '50%' }" class="card">
              <h6>Montant du règlement : {{ tarif?.montant_xof }} FCFA</h6>
              <Button
                v-bind:style="{ width: '35%' }"
                @click="handleCardPayment"
                label="Passer au réglement"
                class="p-mr-2 p-mb-2"
              />
            </div>

            <div
              v-show="canShowPaymentMethod(paymentMethods.wireTransfer)"
              v-bind:style="{ width: '50%' }"
              class="card"
            >
              <h5>Virement Bancaire</h5>
              <Fieldset legend="Détails" :toggleable="true">
                <h6>Veuillez nous transmettre :</h6>
                <h6>- Montant du règlement : {{ tarif?.montant_xof.toLocaleString() }} FCFA</h6>
                <h6>- À adresser à l'ordre du Fonds d'Appui au Développement du Contenu Local (FADCL)</h6>
                <h6>- Nom de la banque : BNDE</h6>
                <h6>- Suivant les détails suivants : IBAN : SN08 SN169 01001 001303696601 55 - BIC : BNDXSNDA</h6>
                <h6>- Numéro de référence à indiquer : {{ ref }}</h6>
                <h6>Votre abonnement sera activé dès la réception de votre paiement.</h6>
              </Fieldset>
              <div class="p-col-12">
                <h5>Télécharger le justificatif de virement <span class="p-text-bold text-red">*</span></h5>
                <upload-file-input
                  :controlLabel="''"
                  :inputLabel="'Choisir une fichier  '"
                  :accept="'.png,.jpeg,.jpg,.pdf'"
                  :blob="multiparts?.paymentJustificationFile.blob"
                  :value="multiparts?.paymentJustificationFile.url"
                  :name="'paymentJustificationFile'"
                  :marginLeft="'0px'"
                  @uploaded="handleUpload"
                  @canceled="handleCancelUpload"
                  @preview-shown="handleShowPreview"
                />
              </div>
            </div>

            <div v-show="canShowPaymentMethod(paymentMethods.payCheck)" v-bind:style="{ width: '50%' }" class="card">
              <h5>Chèque</h5>
              <Fieldset legend="Détails" :toggleable="true">
                <h6>Veuillez nous transmettre :</h6>
                <h6></h6>
                <h6>- Montant du réglement : {{ tarif?.montant_xof.toLocaleString() }} FCFA</h6>
                <h6>- Adresser à l'ordre du Fonds d'Appui au Développement du Contenu Local (FADCL)</h6>
                <h6>- Adresse du détenteur : Villa R 71, Cité Keur Gorgui Dakar – Sénégal</h6>
                <h6>N'oubliez pas d'indiquer le numéro de référence de la commande "{{ ref }}" au dos du chèque.</h6>
                <h6>Votre abonnement sera activé dès la réception de votre paiement.</h6>
              </Fieldset>
              <div class="p-col-12">
                <h5>Télécharger le justificatif de chèque <span class="p-text-bold text-red">*</span></h5>
                <upload-file-input
                  :controlLabel="''"
                  :inputLabel="'Choisir une fichier  '"
                  :accept="'.png,.jpeg,.jpg,.pdf'"
                  :blob="multiparts?.paymentJustificationFile.blob"
                  :value="multiparts?.paymentJustificationFile.url"
                  :name="'paymentJustificationFile'"
                  :marginLeft="'0px'"
                  @uploaded="handleUpload"
                  @canceled="handleCancelUpload"
                  @preview-shown="handleShowPreview"
                />
              </div>
            </div>
          </div>
          <h6 v-show="!isFormValid && submitted" style="color: red">
            Tous les champs suivis d'une étoile (*), doivent être remplis.
          </h6>

          <div>
            <!--Button
              v-bind:style="{ width: '25%' }"
              @click="handlePreviousStep"
              label="Précédent"
              class="p-mr-2 p-mb-2"
              v-show="canPressPreviousStepBtn()"
            />-->
            <Button
              v-bind:style="{ width: '25%' }"
              v-show="canPressSubmitPaymentBtn()"
              @click="handleSubmit"
              label="Payer pour s'abonner"
              class="p-mr-2 p-mb-2 p-button-success"
              :loading="loaders[LoadersIds.Submit]"
            />
          </div>
        </div>
      </div>
    </div>
    <file-viewer
      v-show="null !== fileViewer.url"
      :url-link="fileViewer.url"
      :original-url="fileViewer.originalUrl"
      :title="fileViewer.title"
      v-on:close="handleCloseFileViewed"
    />
  </div>
</template>

<script>
import UploadFileInput from '@/components/upload-file-input';
import LoggedUserMixin from '@/mixins/logged-user';
import FileViewer from '@/components/FileViewer';
import http from '@/services/http.service';
import axios from 'axios';
import moment from 'moment';
import Http from '../../services/http-v2.service';
import { BackendUrls } from '@/services/urlsConfigs';

export default {
  mixins: [LoggedUserMixin],
  components: {
    UploadFileInput,
    FileViewer,
  },
  data() {
    return {
      loaders: [false],
      LoadersIds: {
        Submit: 0,
      },
      loggedUser: {},
      currentSubscription: null,
      tarifications: [],
      paymentMethods: {
        card: 'CARTE_BANCAIRE',
        payCheck: 'CHEQUE',
        wireTransfer: 'VIREMENT',
      },
      payload: {
        subscriptionId: null,
        payCheckNumber: null,
        referencePaiement: null,
        typePaiement: null,
      },
      multiparts: {
        paymentJustificationFile: {
          blob: null,
          url: null,
        },
      },
      fileViewer: {
        url: null,
        originalUrl: null,
        title: null,
      },
      submitted: false,
    };
  },
  async mounted() {
    const touchScript = document.createElement('script');
    touchScript.setAttribute('src', process.env.VUE_APP_TOUCH_PAY_CDN_URL);
    document.head.appendChild(touchScript);

    if (localStorage.getItem('token')) {
      this.getTarifications();

      this.token = localStorage.getItem('token');
      this.config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };

      http
        .get('/users/getProfile', this.config)
        .then((res) => {
          this.loggedUser = res.data;
          this.currentSubscription = this.getCurrentSubscription(this.loggedUser);
        })
        .catch(console.log);
    } else {
      this.$router.push('/login');
    }
  },
  computed: {
    tarif() {
      return this.tarifications.find((tarif) => tarif.rang === this.loggedUser?.entreprise?.rangSouscriptionEnCours);
    },
    paymentReference() {
      return moment.toISOString();
    },
    isFormValid() {
      // console.log(
      //   !!this.multiparts.paymentJustificationFile.blob,
      //   !!this.multiparts.paymentJustificationFile.url,
      //   this.submitted
      // );
      return !!this.multiparts.paymentJustificationFile.blob || !!this.multiparts.paymentJustificationFile.url;
    },
  },
  methods: {
    canShowPaymentMethod(paymentMethod) {
      return this.payload.typePaiement === paymentMethod;
    },

    canPressPreviousStepBtn() {
      return !this.isPaymentRejected(this.currentSubscription);
    },

    canPressSubmitPaymentBtn() {
      return (
        this.isPaymentRejected(this.currentSubscription) ||
        this.isFillingPaymentForm(this.currentSubscription) ||
        this.isSuscriptionTrial(this.currentSubscription) ||
        this.isSuscriptionSuspended(this.currentSubscription)
      );
    },

    checkPoints() {},

    handleUpload(event) {
      const binary = event.binary.target.files[0];
      const name = event.name;
      this.multiparts[name].blob = binary;
      this.multiparts[name].isLoaded = true;
    },

    handleCancelUpload(event) {
      const name = event.name;
      this.multiparts[name].blob = null;
      this.multiparts[name].isLoaded = false;
      this.multiparts[name].url = null;
    },

    handleShowPreview(event) {
      let { url, title, blobType } = event;
      const isLocalUrl = url.includes('localhost') || /^blob/.test(url);
      url = isLocalUrl ? url : 'https' + url.substr(4);

      axios
        .get(url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: blobType });
          this.fileViewer.url = URL.createObjectURL(blob);
          this.fileViewer.originalUrl = url;
          this.fileViewer.title = title;
        })
        .catch(console.error);
    },

    digitTonumber(digit) {
      let str = digit.toString();
      if (str.length <= 1) {
        str = '0' + str;
      }
      return str;
    },

    handleCardPayment() {
      window.sendPaymentInfos(
        new Date().getTime(),
        process.env.VUE_APP_TOUCH_PAY_AGENCY_CODE,
        process.env.VUE_APP_TOUCH_PAY_SECURE_CODE,
        process.env.VUE_APP_TOUCH_PAY_DOMAIN_NAME,
        process.env.VUE_APP_TOUCH_PAY_URL_REDIRECTION_SUCCESS,
        process.env.VUE_APP_TOUCH_PAY_URL_REDIRECTION_FAILED,
        this.tarif.montant_xof,
        process.env.VUE_APP_TOUCH_PAY_CITY,
        this.loggedUser.email,
        this.loggedUser.firstName,
        this.loggedUser.lastName,
        this.loggedUser.mobile
      );
    },

    handlePreviousStep() {
      this.$router.push('/menu/seat');
    },

    handleCloseFileViewed() {
      this.fileViewer.url = null;
      this.fileViewer.title = null;
      this.fileViewer.originalUrl = null;
    },

    handleSubmit() {
      this.submitted = true;
      const year = new Date().getUTCFullYear();
      this.loaders[this.LoadersIds.Submit] = true;
      const paramsNewSubsc = `?subscriptionId=${this.currentSubscription.id}&referencePaiement=${this.referencePaiement}&typePaiement=${this.payload.typePaiement}`;
      const paramsReSubsc = `?subscriptionId=${this.currentSubscription.id}&year=${year}&referencePaiement=${this.referencePaiement}&typePaiement=${this.payload.typePaiement}`;
      const form = new FormData();

      if (!this.isFormValid) return;

      if (this.isSuscriptionTrial(this.currentSubscription) || this.isSuscriptionSuspended(this.currentSubscription)) {
        // FLOW FOR A RESUBSCRIPTION
        form.append('paymentJustificationFile', this.multiparts.paymentJustificationFile.blob);
        Http.post(`${BackendUrls.SubmitReSubscPaymentUrl(this.currentSubscription.id, year)}${paramsReSubsc}`, form, {
          'Content-Type': 'multipart/form-data',
        })
          .then(this.handleSubmitSucceed)
          .catch(this.handleSubmitFailed);
      } else {
        // FLOW FOR A NEW USER WHEN SUBSC STATE # TRIAL OR SUSPENDED

        form.append('paymentJustificationFile', this.multiparts.paymentJustificationFile.blob);
        Http.post(`${BackendUrls.SubmitPaymentUrl(this.currentSubscription.id)}${paramsNewSubsc}`, form, {
          'Content-Type': 'multipart/form-data',
        })
          .then(this.handleSubmitSucceed)
          .catch(this.handleSubmitFailed);
      }
    },

    getTarifications() {
      http.get('/tarifs?limit=100?sort=montant_xof,asc', this.config).then((res) => {
        this.tarifications = res.data;
      });
    },

    handleSubmitSucceed() {
      this.loaders[this.LoadersIds.Submit] = false;
      this.$toast.add({
        severity: 'success',
        summary: 'Accomplie.',
        detail: 'Les informations du paiement ont été soumis avec succès.',
        life: 7000,
      });
      this.$router.push('/');
    },

    handleSubmitFailed(error) {
      this.loaders[this.LoadersIds.Submit] = false;
      this.$toast.add({
        severity: 'error',
        summary: 'Echec de la sauvegarde des données.',
        detail:
          error?.response?.data?.message ||
          'Une erreur est survenue, veuillez recommencer ou contacter votre administrateur.',
        life: 7000,
      });
    },
  },
};
</script>

<style>
.text-red {
  color: #db3c30;
}
.no-arrow {
  -moz-appearance: textfield;
}
.no-arrow::-webkit-inner-spin-button {
  display: none;
}
.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
